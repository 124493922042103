import { markRaw } from 'vue';
import PageReleaseIndex from './release/Index.vue'
import PageSaleLicenseIndex from './sale/LicenseIndex.vue'
import PageIssueIndex from './issues/Index.vue'
import PageArticleIndex from './article/Index.vue'
import PageArticleEdit from './article/Edit.vue'
import PageVisitationIndex from './visitation/Index.vue'
import PageVisitationStatistics from './visitation/Statistics.vue'
export default {
    'release/index' : {title:'发行版本',component:markRaw(PageReleaseIndex)},
    'sale/license/index' : {title:'授权列表',component:markRaw(PageSaleLicenseIndex)},
    'issue/index' : {title:'任务列表',component:markRaw(PageIssueIndex)},
    'article/index' : {title:'文章列表',component:markRaw(PageArticleIndex)},
    'article/edit' : {title:'新建文章',component:markRaw(PageArticleEdit)},
    'visitation/index' : {title:'访问记录',component:markRaw(PageVisitationIndex)},
    'visitation/statistics' : {title:'访问统计',component:markRaw(PageVisitationStatistics)},
};