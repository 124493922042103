<template>
  <div class="h-full overflow-auto">
    <div class="p-3 pt-0">
      <a-descriptions class="bg-gray-100 p-3 rounded">
      <a-descriptions-item label="UserName">Zhou Maomao</a-descriptions-item>
      <a-descriptions-item label="Telephone">1810000000</a-descriptions-item>
      <a-descriptions-item label="Live">Hangzhou, Zhejiang</a-descriptions-item>
      <a-descriptions-item label="Remark">empty</a-descriptions-item>
      <a-descriptions-item label="Address">
        No. 18, Wantang Road, Xihu District, Hangzhou, Zhejiang, China
      </a-descriptions-item>
      </a-descriptions>
    </div>
    <div class="px-3">
      <a-segmented v-model:value="dataRange" :options="dataRangeOptions" />
    </div>
    <statistics-src-domain :data-range="dataRange"/>
    <statistics-region :data-range="dataRange"/>
  </div>
</template>
<script setup>
import StatisticsSrcDomain from './StatisticsSrcDomain.vue';
import StatisticsRegion from './StatisticsRegion.vue';
import { onMounted, ref } from 'vue';
/** @var {String} */
const dataRange = ref('Today');
/** @var {Array<Object>} */
const dataRangeOptions = [
  {label:'今日',value:'Today'},
  {label:'昨日',value:'Date'},
  {label:'7日',value:'Date7Days'},
  {label:'30日',value:'Date30Days'},
  {label:'180日',value:'Date180Days'},
  {label:'365日',value:'Date365Days'},
  {label:'所有',value:'DateAll'}
];

// on mounted
onMounted(mounted);

// on mounted
async function mounted() {
    
}
</script>