<template>
  <page-login v-if="!appStore.accessToken" />
  <a-layout v-else class="h-full">
    <a-layout-header class="!text-white flex flex-row justify-between">
      <div style="font-size: 32px;font-weight:900;">Bittly</div>
      <div>
        <a-dropdown>
          <template #overlay>
            <a-menu>
             <a-menu-item key="2"><UserOutlined /> 账号信息</a-menu-item>
             <a-menu-item key="1"><UserOutlined /> 退出</a-menu-item>
            </a-menu>
          </template>
          <a-button type="text" class="!text-white">
            Admin <DownOutlined />
          </a-button>
        </a-dropdown>
      </div>
    </a-layout-header>
    <a-layout>
      <a-layout-sider>
        <a-menu @click="actionMainMenuItemClick" v-model:selectedKeys="mainMenuSelectedKeys" theme="dark" mode="inline">
          <a-menu-item key="issue/index">
            <CarryOutOutlined />
            <span class="nav-text">任务管理</span>
          </a-menu-item>
          <a-menu-item key="release/index">
            <dropbox-outlined />
            <span class="nav-text">版本管理</span>
          </a-menu-item>
          <a-sub-menu key="sub1">
            <template #title>
              <span><gift-outlined /> 销售管理</span>
            </template>
            <a-menu-item key="sale/license/index"><SafetyOutlined /> 授权列表</a-menu-item>
          </a-sub-menu>
          <a-menu-item key="user-management">
            <CloseCircleOutlined />
            <span class="nav-text">用户管理</span>
          </a-menu-item>
          <a-menu-item key="feedback-management">
            <CloseCircleOutlined />
            <span class="nav-text">反馈管理</span>
          </a-menu-item>
          <a-menu-item key="promotion-management">
            <CloseCircleOutlined />
            <span class="nav-text">推广管理</span>
          </a-menu-item>
          
          <a-sub-menu key="article/index">
            <template #title>
              <span><file-text-outlined /> 文章管理</span>
            </template>
            <a-menu-item key="article/index"><UnorderedListOutlined /> 文章列表 </a-menu-item>
            <a-menu-item key="article/edit"><FormOutlined /> 新建文章 </a-menu-item>
          </a-sub-menu>

          <!-- 访问记录 -->
          <a-sub-menu key="visitation/index">
            <template #title>
              <span><FieldTimeOutlined /> 访问记录</span>
            </template>
            <a-menu-item key="visitation/statistics"><BarChartOutlined /> 访问统计 </a-menu-item>
            <a-menu-item key="visitation/index"><UnorderedListOutlined /> 记录列表 </a-menu-item>
          </a-sub-menu>
        </a-menu>
      </a-layout-sider>
      <a-layout-content class="bg-white p-1">
        <a-tabs hide-add size="small" type="editable-card" class="h-full"
          v-model:activeKey="appStore.tabActiveKey"
          @edit="actionPageTabEdit"
        >
          <a-tab-pane v-for="page in appStore.tabs" :key="page.key" :tab="page.title" :closable="true">
            <component :is="page.component" v-bind="page.props" />
          </a-tab-pane>
        </a-tabs>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script setup>
import { onMounted, ref, markRaw } from 'vue';
import { useAppStore } from './AppStore';
import PageLogin from '@/pages/Login.vue';
import PageRegistry from './pages/Registry';
import PageDashboard from '@/pages/dashboard/Index.vue';
/** @var {AppStore} */
const appStore = useAppStore();
/** @var {Array<String>} */
const mainMenuSelectedKeys = ref([]);
// on mounted
onMounted(mounted);

// on mounted
function mounted() {
    debugger;
    appStore.tabOpen({key:'home',title:'Home',component:markRaw(PageDashboard)});
    appStore.tabActive('home');
    console.log(appStore.tabs);
}

// event handler on main menu item click
function actionMainMenuItemClick( event ) {
    let page = PageRegistry[event.key];
    page.key = event.key;
    appStore.tabOpen(page);
}

// event handler on page tab edit
function actionPageTabEdit( tabKey, action ) {
    if ( 'remove' === action ) {
        appStore.tabClose(tabKey);
    }
}
</script>