<template>
  <a-table size="small"
    :dataSource="licenses" 
    :columns="columns" 
    :pagination="pagination"
    @change="actionTableChange"
  >
    <template #bodyCell="{ record, column }">
      <template v-if="'orderPrice' === column.key">
        <span>{{record.orderPrice/100}}</span>
      </template>
      <template v-if="'expiredAt' === column.key">
        <span>{{dayjs.unix(record.expiredAt).format('YYYY-MM-DD HH:mm:ss')}}</span>
      </template>
      <template v-else-if="'createdAt' === column.key">
        <span>{{dayjs.unix(record.createdAt).format('YYYY-MM-DD HH:mm:ss')}}</span>
      </template>
      <template v-else-if="column.key === 'action'">
        <a-button size="small" class="ml-1"><file-text-outlined /></a-button>
      </template>
    </template>
  </a-table>
</template>
<script setup>
import { onMounted, ref } from 'vue';
import AdminApiClient from '@/utils/AdminApiClient.js';
import dayjs from 'dayjs';
// table columns
const columns = [
    {key:'id',title:'ID',dataIndex:'id'},
    {key:'name',title:'名称',dataIndex: 'name'},
    {key:'email',title:'邮箱',dataIndex: 'email'},
    {key:'type',title:'类型',dataIndex: 'type'},
    {key:'orderName',title:'订单名称',dataIndex: 'orderName'},
    {key:'orderCode',title:'订单号',dataIndex: 'orderCode'},
    {key:'orderStatus',title:'订单状态',dataIndex: 'orderStatus'},
    {key:'orderPrice',title:'价格',dataIndex: 'orderPrice'},
    {key:'expiredAt',title:'过期时间',dataIndex: 'expiredAt'},
    {key:'createdAt',title:'创建时间',dataIndex: 'createdAt'},
    {key:'action',align:'right'},
];
// table data source
const licenses = ref([]);
// pagination
const pagination = ref({
    current:1,
    pageSize:10,
    total:0,
});
/** @var {AdminApiClient} */
let client = null;
// on mounted
onMounted(mounted);

// on mounted
async function mounted() {
    client = AdminApiClient.getClicent();
    await refresh();
}

// refresh
async function refresh() {
    let response = await client.call('license/search', {
        page : pagination.value.current,
        pageSize : pagination.value.pageSize,
    });
    licenses.value = response.list;
    pagination.value.total = response.total;
}

// table change
async function actionTableChange(page) {
    pagination.value.current = page.current;
    await refresh();
}
</script>