import axios from "axios";
import Env from '../../env.js';
import { useAppStore } from '@/AppStore.js';
import { Modal } from 'ant-design-vue';
export default class AdminApiClient {
    // client instance
    static client = null;

    /**
     * gett client instance
     * @returns {AdminApiClient}
     */
    static getClicent() {
        if ( null === AdminApiClient.client ) {
            AdminApiClient.client = new AdminApiClient();
        }
        return AdminApiClient.client;
    }
    
    // constructor
    constructor() {

    }

    /**
     * calling support api
     * @param {String} name
     * @param {Object} params
     * @returns {Promise<Object>}
     **/
    async call( name, params ) {
        let url = `${Env.ADMIN_API_URL_BASE}/admin/${name}`;
        
        let headers = {};
        let appStore = useAppStore();
        headers['PM-ACCESS-TOKEN'] = appStore.accessToken;
        
        let resposne = await axios.post(url, params, {
            validateStatus : null,
            headers : headers,
        });

        // response check and return
        if ( 200 !== resposne.status ) {
            throw Error(`Bittly server error \nstatus : ${resposne.status}\nmessage : ${resposne.statusText}`)
        }
        if ( !resposne.data.success ) {
            Modal.error({title: '请求处理失败',content: resposne.data.message});
            let error = new Error(resposne.data.message);
            error.code = resposne.data.code;
            throw error;
        }
        return resposne.data.data;
    }
}