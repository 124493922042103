// stores/counter.js
import { defineStore } from 'pinia'
export const useAppStore = defineStore('AppStore', {
  state: () => {
    return {
        accessToken : null,
        tabs : [],
        tabActiveKey : null,
    }
  },
  // could also be defined as
  // state: () => ({ count: 0 })
  actions: {
    setAccessToken(token) {
        this.accessToken = token;
    },

    // tab open
    tabOpen( tab ) {
        if ( undefined !== this.tabs.find( item => item.key === tab.key ) ) {
            this.tabActive(tab.key);
            return ;
        }

        tab.props = tab.props || {};
        tab.props.tabKey = tab.key;
        this.tabs.push(tab);
        this.tabActive(tab.key);
    },

    // tab active
    tabActive( key ) {
        this.tabActiveKey = key;
    },

    // tab close
    tabClose( key ) {
        let index = this.tabs.findIndex( item => item.key === key );
        if ( -1 === index ) {
            return ;
        }

        let tab = this.tabs[index];
        console.log(`Application tab close: (${tab.title})[#${tab.key}]`);

        this.tabs.splice(index, 1);
        if ( this.tabActiveKey === key ) {
            this.tabActiveKey = null;
            if ( 0 < index ) {
                this.tabActive(this.tabs[index - 1].key);
            } else {
                this.tabActive(this.tabs[0].key);
            }
        }
    }
  },
})