<template>
  <a-table size="small"
    :dataSource="releasese" 
    :columns="columns" 
    :pagination="pagination"
    @change="actionTableChange"
  >
    <!-- header -->
    <template #headerCell="{ column }">
      <template v-if="column.key === 'action'">
        <span>
          <a-button type="text" @click="actionCreate"><plus-circle-outlined /></a-button>
        </span>
      </template>
    </template>

    <!-- body -->
    <template #bodyCell="{ record, column }">
      <template v-if="'packageSize' === column.key">
        <span v-if="null !== record.packageInfo">{{ MyNumber.formatAsFileSize(record.packageInfo.size) }}</span>
      </template>
      <template v-else-if="column.key === 'action'">
        <a-button v-if="null !== record.packageInfo" size="small" class="ml-1" @click="actionDownload(record)"><DownloadOutlined /></a-button>

        <a-button v-if="'new' === record.status" size="small" class="ml-1" @click="actionEditDescription(record)"><file-text-outlined /></a-button>
        
        <a-popconfirm v-if="['new','pre-released'].includes(record.status)"
          title="是否发布该发行版本" 
          placement="leftBottom" 
          @confirm="actionPublish(record)"
        ><a-button size="small" class="ml-1"><check-circle-outlined /></a-button></a-popconfirm>

        <a-popconfirm v-if="'new' === record.status"
          title="是否发布该版本为预发行版本？" 
          placement="leftBottom" 
          @confirm="actionPublishPreRelease(record)"
        ><a-button size="small" class="ml-1"><issues-close-outlined /></a-button></a-popconfirm>

        <!-- 删除 -->
        <a-popconfirm v-if="'new' === record.status || ''"
          title="是否删除该发行版本" 
          placement="leftBottom" 
          @confirm="actionDelete(record)"
        ><a-button size="small" class="ml-1" danger><delete-outlined /></a-button></a-popconfirm>
      </template>
    </template>
  </a-table>

  <!-- edit -->
  <edit v-if="null !== editTarget" 
    v-model:value="editTarget" 
    @done="actionEditDone" 
    @cancel="actionEditCancel"
  ></edit>

  <!-- edit description -->
  <edit-description 
    v-if="null !== editTargetDescription" 
    :value="editTargetDescription" 
    @done="actionEditDescriptionDone"
    @cancel="actionEditDescriptionCancel"
  ></edit-description>
</template>
<script setup>
import { onMounted, ref } from 'vue';
import AdminApiClient from '@/utils/AdminApiClient.js';
import Edit from './Edit.vue';
import EditDescription from './EditDescription.vue';
import MyNumber from '@/utils/MyNumber';
// table columns
const columns = [
    {key:'id',title:'ID',dataIndex:'id'},
    {key:'status',title:'状态',dataIndex: 'status'},
    {key:'arch',title:'架构',dataIndex: 'arch'},
    {key:'platform',title:'平台',dataIndex: 'platform'},
    {key:'packageType',title:'包类型',dataIndex: 'packageType'},
    {key:'packageSize',title:'包大小'},
    {key:'version',title:'版本',dataIndex: 'version'},
    {key:'releasedAt',title:'发布日期',dataIndex: 'releasedAt'},
    {key:'action',width:180,align:'right'},
];
// table data source
const releasese = ref([]);
// edit object
const editTarget = ref(null);
// edit object
const editTargetDescription = ref(null);
// pagination
const pagination = ref({
    current:1,
    pageSize:10,
    total:0,
});
/** @var {AdminApiClient} */
let client = null;
// on mounted
onMounted(mounted);

// on mounted
async function mounted() {
    client = AdminApiClient.getClicent();
    await refresh();
}

// refresh
async function refresh() {
    let response = await client.call('release/search', {
        page : pagination.value.current,
        pageSize : pagination.value.pageSize,
    });
    releasese.value = response.list;
    pagination.value.total = response.total;
}

// action create
function actionCreate() {
    editTarget.value = {};
}

// action edit done
async function actionEditDone() {
    editTarget.value = null;
    await refresh();
}

// action edit cancel
function actionEditCancel() {
    editTarget.value = null;
}

// action edit description done
async function actionEditDescriptionDone() {
    editTargetDescription.value = null;
    await refresh();
}

// action edit description cancel
function actionEditDescriptionCancel() {
    editTargetDescription.value = null;
}

// delete record
async function actionDelete(record) {
    await client.call('release/delete', {id:record.id});
    await refresh();
}

// publish record
async function actionPublish(record) {
    await client.call('release/publish', {id:record.id});
    await refresh();
}

// publish pre release record
async function actionPublishPreRelease(record) {
    await client.call('release/publish-pre-release', {id:record.id});
    await refresh();
}

// edit record
async function actionEditDescription(record) {
    editTargetDescription.value = {desc:record.description,id:record.id};
}

// table change
async function actionTableChange(page) {
    pagination.value.current = page.current;
    await refresh();
}

// download
function actionDownload(record) {
    window.location.href = record.packageInfo.url;
}
</script>