import { createApp } from 'vue'
import { createPinia } from 'pinia'
import Antd from 'ant-design-vue';
import * as AntdIcons from '@ant-design/icons-vue'
import 'ant-design-vue/dist/reset.css';
import './styles/tailwind.css';
import App from './App.vue'
import './styles/app.css';

let app = createApp(App);
app.use(createPinia());
app.use(Antd);
(() => {
    let icons = AntdIcons;
    for ( let icon in icons ) {
        app.component(icon, icons[icon]);
    }
})();
app.mount('#app');
