<template>
  <a-modal v-if="null !== editTarget" :open="true" title="版本编辑" 
    @ok="actionOk"
    @cancel="actionCancel"
  >
    <a-form :label-col="{span:4}" :wrapper-col="{span:20}" label-align="left">
      <a-form-item label="平台">
        <a-radio-group button-style="solid" v-model:value="editTarget.platform">
          <a-radio-button value="win32">Window</a-radio-button>
          <a-radio-button value="linux">Linux</a-radio-button>
          <a-radio-button value="mac">Mac</a-radio-button>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="架构">
        <a-radio-group button-style="solid" v-model:value="editTarget.arch">
          <a-radio-button value="x64">x64</a-radio-button>
          <a-radio-button value="x32">x32</a-radio-button>
          <a-radio-button value="arm32">arm32</a-radio-button>
          <a-radio-button value="arm64">arm64</a-radio-button>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="包类型">
        <a-radio-group button-style="solid" v-model:value="editTarget.packageType">
          <a-radio-button value="nsis">nsis</a-radio-button>
          <a-radio-button value="nsis-web">nsis-web</a-radio-button>
          <a-radio-button value="portable">portable</a-radio-button>
          <a-radio-button value="zip">zip</a-radio-button>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="版本">
        <a-radio-group button-style="solid" v-model:value="editTarget.versionType">
          <a-radio-button value="major">主版本</a-radio-button>
          <a-radio-button value="minor">次版本</a-radio-button>
          <a-radio-button value="patch">修正版本</a-radio-button>
        </a-radio-group>
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script setup>
import { ref, watch } from 'vue';
import AdminApiClient from '@/utils/AdminApiClient';
/** @var {Function} */
const emits = defineEmits(['done','cancel']);
/** @var {Object} */
const props = defineProps({
    value : Object, // edit target
});
/** @var{} */
const editTarget = ref(null);
// watch props value
watch(() => props.value, handlePropsValueChange, {immediate:true});

// handle props value change
function handlePropsValueChange() {
    editTarget.value = props.value;
    editTarget.value = Object.assign({
        platform : 'win32',
        arch : 'x64',
        packageType : 'nsis',
        versionType : 'patch',
    }, props.value);
}

// action ok
async function actionOk() {
    let client = AdminApiClient.getClicent();
    await client.call('release/save', {
        id : editTarget.value.id,
        data:editTarget.value
    });
    emits('done');
}

// action cancel
function actionCancel() {
    emits('cancel');
}
</script>