<template>
  <a-row class="h-96 mt-5">
    <a-col :span="8">
      <div ref="pieContainer" class="w-full h-full"></div>
    </a-col>
    <a-col :span="16">
      <div v-if="lineChartEnable" ref="lineContainer" class="w-full h-full"></div>
      <div v-else ref="barContainer" class="w-full h-full"></div>
    </a-col>
  </a-row>
</template>
<script setup>
import * as echarts from 'echarts';
import AdminApiClient from '@/utils/AdminApiClient';
import { onMounted, watch, ref, nextTick } from 'vue';
/** @var {Object} */
const props = defineProps({
    dataRange: String, // data range
});
/** @var {ECharts} */
let pieChart = null;
/** @var {HtmlElement} */
const pieContainer = ref(null);
/** @var {Object} */
const pieChartOptions = {
    title: {text: '来源网站', left: 'center'},
    tooltip: {trigger: 'item'},
    series: [{
        type: 'pie',
        radius: '80%',
        data: [],
        emphasis: {itemStyle: {shadowBlur: 10,shadowOffsetX: 0,shadowColor: 'rgba(0, 0, 0, 0.5)'}}
    }]
};
/** @var {ECharts} */
let lineChart = null;
/** @var {Boolean} */
const lineChartEnable = ref(false);
/** @var {HtmlElement} */
const lineContainer = ref(null);
/** @var {Object} */
const lineChartOptions = {
    title: {text: '来源网站'},
    tooltip: {trigger: 'axis'},
    grid: {left: '3%',right: '4%',bottom: '3%',containLabel: true},
    yAxis: {type: 'value'},
    xAxis: {type: 'category', boundaryGap: false, data: []},
    legend: {data: []},
    series: []
};
/** @var {ECharts} */
let barChart = null;
/** @var {HtmlElement} */
const barContainer = ref(null);
/** @var {Object} */
const barChartOptions = {
    title: {text: '来源网站', left: 'center'},
    tooltip: {trigger: 'axis'},
    xAxis: {type: 'category', data: []},
    yAxis: {type: 'value'},
    series: [{data: [], type: 'bar'}],
    emphasis: {itemStyle: {shadowBlur: 10,shadowOffsetX: 0,shadowColor: 'rgba(0, 0, 0, 0.5)'}}
};
// on mounted
onMounted(mounted);
// watch data range
watch(() => props.dataRange, setupCharts);

// mounted
async function mounted() {
    await setupCharts();
}

// setup charts
async function setupCharts() {
    if ( null !== pieChart ) {
        pieChart.dispose();
    }
    if ( null !== barChart ) {
        barChart.dispose();
    }
    if ( null !== lineChart ) {
        lineChart.dispose();
    }

    lineChartEnable.value = false;
    if ( !['Today','Date'].includes(props.dataRange) ) {
        lineChartEnable.value = true;
    }
    await nextTick();

    let date = new Date();
    if ( 'Today' !== props.dataRange ) {
        date.setDate(date.getDate() - 1);
    }
    date = [
        date.getFullYear(), 
        (date.getMonth() + 1).toString().padStart(2, '0'),
        date.getDate().toString().padStart(2, '0')
    ].join('-');
    await setupPieChart(date);
    await setupBarChart(date);
    await setupLineChart(date);
}

// 访问来源网站折线图
async function setupLineChart( date ) {
    if ( !lineChartEnable.value ) {
        return;
    }

    lineChart = echarts.init(lineContainer.value);
    lineChartOptions.xAxis.data = [];
    lineChartOptions.legend.data = [];
    lineChartOptions.series.data = [];
    
    // 刷新折线图数据
    let type = 'VisitSrcDomainDate';
    let dateRangeStart = new Date();
    switch (props.dataRange) {
    case 'Date7Days': dateRangeStart.setDate(dateRangeStart.getDate() - 7); break;
    case 'Date30Days': dateRangeStart.setDate(dateRangeStart.getDate() - 30); break;
    case 'Date180Days': dateRangeStart.setDate(dateRangeStart.getDate() - 180); break;
    case 'Date365Days': dateRangeStart.setDate(dateRangeStart.getDate() - 365); break;
    case 'DateAll': dateRangeStart.setDate(dateRangeStart.getDate() - 365 * 10); break;
    }
    dateRangeStart = [
        dateRangeStart.getFullYear(), 
        (dateRangeStart.getMonth() + 1).toString().padStart(2, '0'),
        dateRangeStart.getDate().toString().padStart(2, '0')
    ].join('-');

    let client = AdminApiClient.getClicent();
    let response = await client.call('statistics/get-contents-by-range', {type,start:dateRangeStart,end:date});
    lineChartOptions.xAxis.data = Object.keys(response);
    for (let key in response) {
        let items = response[key];
        for (let item of items) {
            let domain = '' === item.srcDomain ? '直接访问' : item.srcDomain;
            if ( !lineChartOptions.legend.data.includes(domain) ) {
                lineChartOptions.legend.data.push(domain);
                lineChartOptions.series.push({name:domain,type:'line',data:[]});
            }
            let series = lineChartOptions.series.find(series => series.name === domain);
            series.data.push(item.vstCount);
        }
    }
    lineChart.setOption(lineChartOptions);
}

// 访问来源网站柱状图
async function setupBarChart( date ) {
    if ( lineChartEnable.value ) {
        return;
    }

    let type = 'VisitSrcDomainToday';
    if ( 'Today' !== props.dataRange ) {
        type = `VisitSrcDomain${props.dataRange}`;
    }

    barChart = echarts.init(barContainer.value);
    barChartOptions.xAxis.data = [];
    barChartOptions.series[0].data = [];

    let client = AdminApiClient.getClicent();
    let response = await client.call('statistics/get-content', {type:type,date:date});
    for (let item of response) {
        barChartOptions.xAxis.data.push('' === item.srcDomain ? '直接访问' : item.srcDomain);
        barChartOptions.series[0].data.push(item.vstCount);
    }
    barChart.setOption(barChartOptions);
}

// 访问来源网站饼图
async function setupPieChart(date) {
    let type = 'VisitSrcDomainToday';
    if ( 'Today' !== props.dataRange ) {
        type = `VisitSrcDomain${props.dataRange}`;
    }

    pieChart = echarts.init(pieContainer.value);
    
    // 刷新饼图数据
    pieChartOptions.series[0].data = [];
    let client = AdminApiClient.getClicent();
    let response = await client.call('statistics/get-content', {type,date});
    for (let item of response) {
        let dataItem = {};
        dataItem.value = item.vstCount;
        dataItem.name = '' === item.srcDomain ? '直接访问' : item.srcDomain;
        pieChartOptions.series[0].data.push(dataItem);
    }

    pieChart.setOption(pieChartOptions);
}
</script>