<template>
  <a-table size="small"
    :dataSource="visitLogs" 
    :columns="columns" 
    :scroll="{ x: 'max-content' }"
    :pagination="pagination"
    @change="actionTableChange"
  >
    <template #bodyCell="{ column, record }">
      <template v-if="column.key === 'url'">
        <div class="whitespace-nowrap text-ellipsis overflow-hidden" 
          style="max-width: 500px;"
          :title="record.url"
        >{{ record.url }}</div>
      </template>
    </template>
  </a-table>
</template>
<script setup>
import { onMounted, ref } from 'vue';
import AdminApiClient from '@/utils/AdminApiClient.js';
// table columns
const columns = [
    {key:'id',title:'ID',dataIndex:'id',width:50,fixed:true},
    {key:'createdAt',title:'访问时间',dataIndex: 'createdAt',width:150,fixed:true},
    {key:'url',title:'URL',dataIndex:'url',fixed:true},
    {key:'referrer',title:'Referrer',dataIndex:'referrer'},
    {key:'ua',title:'UA',dataIndex:'ua'},
    {key:'ip',title:'IP',dataIndex:'ip',width:80},
    {key:'ipCountry',title:'国家',dataIndex:'ipCountry',width:80},
    {key:'ipRegion',title:'省份',dataIndex:'ipRegion',width:80},
    {key:'ipCity',title:'城市',dataIndex:'ipCity',width:80},
    {key:'ipIsp',title:'ISP',dataIndex:'ipIsp',width:80},
    {key:'action',align:'right',width:100},
];
// table data source
const visitLogs = ref([]);
// pagination
const pagination = ref({
    current:1,
    pageSize:18,
    total:0,
});
/** @var {AdminApiClient} */
let client = null;
// on mounted
onMounted(mounted);

// on mounted
async function mounted() {
    client = AdminApiClient.getClicent();
    await refresh();
}

// refresh
async function refresh() {
    let response = await client.call('visit-log/search', {
        page : pagination.value.current,
        pageSize : pagination.value.pageSize,
    });
    visitLogs.value = response.list;
    pagination.value.total = response.total;
}

// table change
async function actionTableChange(page) {
    pagination.value.current = page.current;
    await refresh();
}
</script>