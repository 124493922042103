<template>
  <div class="h-full overflow-y-auto p-3">
  <a-form v-if="null !== article">
    <a-form-item>
      <a-input v-model:value="article.title" placeholder="文章标题"/>
    </a-form-item>

    <a-form-item>
      <MdEditor 
        v-model="article.content" 
        @onSave="actionSave"
        @onUploadImg="actionUploadImage"
      ></MdEditor>
    </a-form-item>

    <a-form-item>
      <a-textarea v-model:value="article.summary" placeholder="文章摘要" :rows="3" class="mt-1 mb-1" />
    </a-form-item>

    <a-form-item>
      <a-button type="primary" @click="actionSave">保存</a-button>
    </a-form-item>
  </a-form>
  </div>
</template>
<script setup>
import { onMounted, ref } from 'vue';
import { MdEditor } from 'md-editor-v3';
import { useAppStore } from '@/AppStore.js';
import AdminApiClient from '@/utils/AdminApiClient';
import 'md-editor-v3/lib/style.css';
/** @var {AppStore} */
const appStore = useAppStore();
/** @var {Objects} */
const props = defineProps({
    tabKey : {type:String, default:null},
    article : {type:Object, default:null},
});
/** @var {Object} */
const article = ref(null);
// on mounted
onMounted(mounted);

// mounted
function mounted() {
    article.value = {};
    article.value.title = '';
    article.value.content = '';
    article.value.summary = '';
    if ( null !== props.article ) {
        article.value = structuredClone(props.article);
    }
}

// file get base64 content
function fileGetBase64Content(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}


// save article
async function actionSave() {
    let client = AdminApiClient.getClicent();
    await client.call('article/save', {
        id : article.value.id, 
        data : article.value
    });
    alert('保存成功');
    appStore.tabClose(props.tabKey);
}

// upload image
async function actionUploadImage( files, callback ) {
    let client = AdminApiClient.getClicent();
    let urls = [];
    for ( let file of files ) {
        let content = await fileGetBase64Content(file);
        content = content.split(',')[1];
        let response = await client.call('article/upload-image', {
            content : content
        });
        urls.push(response.url);
    }
    callback(urls);
}
</script>