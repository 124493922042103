<template>
  <a-row class="h-96 mt-5">
    <a-col :span="8">
      <div ref="mapContainer" class="w-full h-full"></div>
    </a-col>
    <a-col :span="16">
      <div ref="barContainer" class="w-full h-full"></div>
    </a-col>
  </a-row>
</template>
<script setup>
import * as echarts from 'echarts';
import GeoChina from './GeoChina';
import AdminApiClient from '@/utils/AdminApiClient';
import { onMounted, watch, ref, nextTick } from 'vue';
/** @var {Object} */
const props = defineProps({
    dataRange: String, // data range
});
/** @var {ECharts} */
let mapChart = null;
/** @var {HtmlElement} */
const mapContainer = ref(null);
/** @var {Object} */
const mapChartOptions = {
    title: {text: '来源地区', left: 'center'},
    tooltip: {trigger: 'item'},
    visualMap: {
        show : false,
        min: 0,
        max: 0,
        calculable: true,
        inRange: {color: ['#ffffff', '#1677ff']}
    },
    series: [{
        type: 'map',
        map: 'china',
        data : [],
    }]
};
/** @var {ECharts} */
let barChart = null;
/** @var {HtmlElement} */
const barContainer = ref(null);
/** @var {Object} */
const barChartOptions = {
    title: {text: '来源地区', left: 'center'},
    tooltip: {trigger: 'axis'},
    xAxis: {type: 'category', data: []},
    yAxis: {type: 'value'},
    series: [{data: [], type: 'bar'}],
    emphasis: {itemStyle: {shadowBlur: 10,shadowOffsetX: 0,shadowColor: 'rgba(0, 0, 0, 0.5)'}}
};
// on mounted
onMounted(mounted);
// watch data range
watch(() => props.dataRange, setupCharts);

// mounted
async function mounted() {
    echarts.registerMap('china', GeoChina);
    await setupCharts();
}

// setup charts
async function setupCharts() {
    if ( null !== mapChart ) {
        mapChart.dispose();
    }
    if ( null !== barChart ) {
        barChart.dispose();
    }
    await nextTick();

    let date = new Date();
    if ( 'Today' !== props.dataRange ) {
        date.setDate(date.getDate() - 1);
    }
    date = [
        date.getFullYear(), 
        (date.getMonth() + 1).toString().padStart(2, '0'),
        date.getDate().toString().padStart(2, '0')
    ].join('-');
    await setupMapChart(date);
    await setupBarChart(date);
}

// 访问来源网站柱状图
async function setupBarChart( date ) {
    let type = 'VisitIpRegionToday';
    if ( 'Today' !== props.dataRange ) {
        type = `VisitIpRegion${props.dataRange}`;
    }

    barChart = echarts.init(barContainer.value);
    barChartOptions.xAxis.data = [];
    barChartOptions.series[0].data = [];

    let client = AdminApiClient.getClicent();
    let response = await client.call('statistics/get-content', {type:type,date:date});
    for (let item of response) {
        barChartOptions.xAxis.data.push(item.ipRegion);
        barChartOptions.series[0].data.push(item.vstCount);
    }
    barChart.setOption(barChartOptions);
}

// 访问来源网站地区图
async function setupMapChart(date) {
    let type = 'VisitIpRegionToday';
    if ( 'Today' !== props.dataRange ) {
        type = `VisitIpRegion${props.dataRange}`;
    }

    mapChart = echarts.init(mapContainer.value);
    mapChartOptions.visualMap.max = 0;
    mapChartOptions.series[0].data = [];

    let client = AdminApiClient.getClicent();
    let response = await client.call('statistics/get-content', {type:type,date:date});
    for (let item of response) {
        if ( item.vstCount > mapChartOptions.visualMap.max ) {
            mapChartOptions.visualMap.max = item.vstCount;
        }
        mapChartOptions.series[0].data.push({
            name: item.ipRegion,
            value: item.vstCount
        });

    }

    mapChart.setOption(mapChartOptions);
}
</script>