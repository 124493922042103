<template>
  <a-modal v-if="null !== editTarget" :open="true" title="描述编辑" 
    @ok="actionOk"
    @cancel="actionCancel"
  >
    <a-textarea
      v-model:value="editTarget.desc"
      :auto-size="{ minRows: 10}"
    />
  </a-modal>
</template>
<script setup>
import { ref, watch } from 'vue';
import AdminApiClient from '@/utils/AdminApiClient';
/** @var {Function} */
const emits = defineEmits(['done','cancel']);
/** @var {Object} */
const props = defineProps({
    value : Object, // edit target
});
/** @var{} */
const editTarget = ref(null);
// watch props value
watch(() => props.value, handlePropsValueChange, {immediate:true});

// handle props value change
function handlePropsValueChange() {
    editTarget.value = props.value;
}

// action ok
async function actionOk() {
    let client = AdminApiClient.getClicent();
    await client.call('release/update-description', {
        id : editTarget.value.id,
        content:editTarget.value.desc
    });
    emits('done');
}

// action cancel
function actionCancel() {
    emits('cancel');
}
</script>