<template>
  <div class="h-full bg-gray-100 flex flex-col justify-center items-center">
    <a-form class="w-96" :label-col="{span:4}" :wrapper-col="{span:20}" labelAlign="left">
      <a-form-item label="账号">
        <a-input v-model:value="account" />
      </a-form-item>

      <a-form-item label="密码">
        <a-input-password v-model:value="password" />
      </a-form-item>

      <a-form-item :wrapper-col="{offset:4,span:16}">
        <a-button type="primary" html-type="submit" @click="actionLogin">登录</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script setup>
import AdminApiClient from "@/utils/AdminApiClient";
import { ref } from "vue";
import { useAppStore } from '@/AppStore.js';
/** @var {String} */
const account = ref("");
/** @var {String} */
const password = ref("");
/** @var {AppStore} */
const appStore = useAppStore();
// 登录
async function actionLogin() {
    let client = AdminApiClient.getClicent();
    let response = await client.call('user/login', {
        account: account.value,
        password: password.value
    });    
    appStore.setAccessToken(response.accessToken);
}
</script>