<template>
  <a-row>
    <a-col :span="4" class="p-1">
     <div class="bg-red-400 p-5 rounded">
       <h2 class="text-slate-50">今日下载量：<span class="text-6xl">123</span></h2>

     </div>
    </a-col>
    <a-col :span="4" class="p-1">
      <div class="bg-green-400">ssss</div>
    </a-col>
    <a-col :span="4" class="p-1">
      <div class="bg-blue-400">ssss</div>
    </a-col>
    <a-col :span="4" class="p-1">
      <div class="bg-yellow-400">ssss</div>
    </a-col>
    <a-col :span="4" class="p-1">
      <div class="bg-pink-400">ssss</div>
    </a-col>
    <a-col :span="4" class="p-1">
      <div class="bg-slate-400">ssss</div>
    </a-col>
  </a-row>
</template>
<script setup>
</script>