<template>
  <a-table size="small"
    :dataSource="articles" 
    :columns="columns" 
    :pagination="pagination"
    @change="actionTableChange"
  >
    <template #headerCell="{ column }">
      <template v-if="column.key === 'action'">
        <a-button size="small" type="text" @click="actionCreate"><plus-circle-outlined /></a-button>
        <a-button size="small" type="text" @click="actionRefresh"><RedoOutlined /></a-button>
      </template>
    </template>

    <template #bodyCell="{ record, column }">
      <template v-if="column.key === 'action'">
        <a-popconfirm
          title="是否删除该文章？" 
          placement="leftBottom" 
          @confirm="actionDelete(record)"
        ><a-button size="small" class="ml-1"><delete-outlined /></a-button></a-popconfirm>
        
        <a-button size="small" class="ml-1" 
          @click="actionEdit(record)"
        ><form-outlined /></a-button>

        <a-button v-if="['new'].includes(record.status)" 
          size="small" 
          class="ml-1" 
          @click="actionPublish(record)"
        ><check-circle-outlined /></a-button>
      </template>
    </template>
  </a-table>
</template>
<script setup>
import { onMounted, ref } from 'vue';
import { useAppStore } from '@/AppStore.js';
import AdminApiClient from '@/utils/AdminApiClient.js';
import ArticleEdit from './Edit.vue';
/** @var {AppStore} */
const appStore = useAppStore();
// table columns
const columns = [
    {key:'id',title:'ID',dataIndex:'id',width:50},
    {key:'title',title:'标题',dataIndex:'title'},
    {key:'status',title:'状态',dataIndex: 'status',width:80},
    {key:'createdAt',title:'创建时间',dataIndex: 'createdAt',width:150},
    {key:'action',align:'right',width:150},
];
// table data source
const articles = ref([]);
// pagination
const pagination = ref({
    current:1,
    pageSize:10,
    total:0,
});
/** @var {AdminApiClient} */
let client = null;
// on mounted
onMounted(mounted);

// on mounted
async function mounted() {
    client = AdminApiClient.getClicent();
    await refresh();
}

// refresh
async function refresh() {
    let response = await client.call('article/search', {
        page : pagination.value.current,
        pageSize : pagination.value.pageSize,
    });
    articles.value = response.list;
    pagination.value.total = response.total;
}

// table change
async function actionTableChange(page) {
    pagination.value.current = page.current;
    await refresh();
}

// refresh
async function actionRefresh() {
    await refresh();
}

// create article
function actionCreate() {
    appStore.tabOpen({key:'article/edit',title:'新建文章',component:ArticleEdit});
}

// close issue
async function actionDelete(record) {
    await client.call('article/delete', {id : record.id,});
    await refresh();
}

// resolve issue
async function actionPublish(record) {
    await client.call('article/publish', {id : record.id,});
    await refresh();
}

// edit
async function actionEdit(record) {
    appStore.tabOpen({
        key:`article/edit/${record.id}`,
        title:'编辑文章',
        component:ArticleEdit,
        props : {article:record},
    });
}
</script>