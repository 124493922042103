export default class MyNumber {
    // format a number as a file size
    static formatAsFileSize ( size ) {
        if ( size < 1024 ) {
            return size + ' B';
        }
        size /= 1024;
        if ( size < 1024 ) {
            return size.toFixed(2) + ' KB';
        }
        size /= 1024;
        if ( size < 1024 ) {
            return size.toFixed(2) + ' MB';
        }
        size /= 1024;
        if ( size < 1024 ) {
            return size.toFixed(2) + ' GB';
        }
        size /= 1024;
        return size.toFixed(2) + ' TB';
    }
}