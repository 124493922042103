<template>
  <a-table size="small"
    :dataSource="issues" 
    :columns="columns" 
    :pagination="pagination"
    @change="actionTableChange"
  >
    <template #bodyCell="{ record, column }">
      <template v-if="column.key === 'action'">
        <a-popconfirm
          title="是否关闭该任务" 
          placement="leftBottom" 
          @confirm="actionClose(record)"
        ><a-button size="small" class="ml-1"><CloseCircleOutlined /></a-button></a-popconfirm>
        
        <a-button v-if="['new'].includes(record.status)" 
          size="small" 
          class="ml-1" 
          @click="actionResolve(record)"
        ><CheckCircleOutlined /></a-button>
      </template>
    </template>
  </a-table>
</template>
<script setup>
import { onMounted, ref } from 'vue';
import AdminApiClient from '@/utils/AdminApiClient.js';
// table columns
const columns = [
    {key:'id',title:'ID',dataIndex:'id',width:50},
    {key:'type',title:'类型',dataIndex: 'type',width:80},
    {key:'status',title:'状态',dataIndex: 'status',width:80},
    {key:'title',title:'标题',dataIndex: 'title'},
    {key:'createdAt',title:'创建时间',dataIndex: 'createdAt',width:150},
    {key:'action',align:'right',width:100},
];
// table data source
const issues = ref([]);
// pagination
const pagination = ref({
    current:1,
    pageSize:10,
    total:0,
});
/** @var {AdminApiClient} */
let client = null;
// on mounted
onMounted(mounted);

// on mounted
async function mounted() {
    client = AdminApiClient.getClicent();
    await refresh();
}

// refresh
async function refresh() {
    let response = await client.call('issue/search', {
        page : pagination.value.current,
        pageSize : pagination.value.pageSize,
    });
    issues.value = response.list;
    pagination.value.total = response.total;
}

// table change
async function actionTableChange(page) {
    pagination.value.current = page.current;
    await refresh();
}

// resolve issue
async function actionResolve(issue) {
    await client.call('issue/resolve', {id : issue.id,});
    await refresh();
}

// close issue
async function actionClose(record) {
    await client.call('issue/close', {id : record.id,});
    await refresh();
}
</script>